const mn = {
    network: {
      tips: `Сонгосон сүлжээ одоогоор дэмжигдэхгүй байна!
      Polygon (Matic) сүлжээг сонгоно уу!'`,
      ev: "MetaMask, Trust Wallet, imToken, TokenPocket зэрэг хэтэвч програмууд суулгасан эсэхээ шалгана уу.",
      copyight: process.env.VUE_APP_TITLE_NAME,
      all: "Max",
      bolck: "Dai тогтвортой зоос",
      law: `<h3><strong style="color:yellow">Энэ DAPP нь зарим улс орон, бүс нутагт хууль эрх зүйн хамгаалалтгүй тул хэрэглэгч та анхааралтай ашиглана уу!</strong></h3>
      <br/>
      <p><strong>Хүндэт хэрэглэгчид ээ:</strong></p>
  <br/>
      <p>Энэхүү DAPP-д хандсан танд талархал илэрхийлье. Таны хууль ёсны эрхийг хамгаалж, холбогдох хууль эрх зүйн эрсдлүүдийг ойлгоход туслах үүднээс дараах чухал мэдэгдлийг гаргаж байна.</p><br/>
  
      <p>Энэхүү DAPP нь криптовалютын хөрөнгө оруулалт болон арилжаатай холбоотой бөгөөд зарим улс орон, бүс нутагт хууль тогтоомжийг зөрчиж болзошгүй юм. Орон нутгийн хууль тогтоомж болон зохицуулах байгууллагуудын бодлогын дагуу, энэхүү DAPP нь тодорхой улс орон, бүс нутагт хууль ёсны дагуу ажиллах боломжгүй тул бид дараах мэдэгдлийг гаргаж байна.</p>
  <br/>
      <h3><strong>1. Үйлчилгээний хамрах хүрээний хязгаарлалт:</strong></h3><br/>
      <p style="color:yellow">Энэхүү DAPP нь доорх улс орон, бүс нутагт криптовалютын хөрөнгө оруулалт болон арилжааны үйлчилгээ үзүүлэхгүй бөгөөд ямар ч техникийн дэмжлэг, борлуулалтын дараах үйлчилгээ үзүүлэхгүй:</p><br/>
      <p style="color:yellow">Хятад (БНХАУ-ын эх газар, Хонг Конгийн тусгай захиргааны бүс, Макаогийн тусгай захиргааны бүс, Тайвань)/ Энэтхэг/ Алжир/ Бангладеш/ Марокко/ Египет/ Непал/ Пакистан/ Катар/ Ирак/ Тунис/ Афганистан/ Боливи.</p>
     <br/> <strong>Дээр дурдсан улс орон, бүс нутагт энэхүү DAPP-г ашиглан криптовалютын хөрөнгө оруулалт, арилжаа хийх хэрэглэгчдэд ашиглалтыг нэн даруй зогсоож, хууль эрх зүйн эрсдлээс зайлсхийхийг зөвлөж байна.</strong>
  <br/><br/>
      <h3><strong>2. Хууль эрх зүйн эрсдэл болон хөрөнгө оруулалтын эрсдлийн анхааруулга:</strong></h3><br/>
      <p>Дээр дурдсан улс орон, бүс нутагт энэхүү DAPP-г ашиглан криптовалютын хөрөнгө оруулалт, арилжаа хийх нь ноцтой хууль эрх зүйн үр дагаварт хүргэж болзошгүй. Манай баг ийм үйлдлээс үүдэн гарах аливаа хууль эрх зүйн хариуцлагыг хүлээхгүй болно. Мөн криптовалютын зах зээл нь өөрөө өндөр хэлбэлзэлтэй, урьдчилан таамаглах боломжгүй бөгөөд хөрөнгө оруулалтын үйл ажиллагаа ихээхэн эрсдэлтэй байдаг. Та энэхүү DAPP-г ашиглан хөрөнгө оруулалт, арилжаа хийх эсэхээ шийдэхээс өмнө холбогдох эрсдлүүдийг бүрэн ойлгож, баталгаажуулахыг зөвлөж байна.</p>
  <br/>
      <h3><strong>3. Хэрэглэгчийн баталгаа:</strong></h3><br/>
      <p>Хэрэв та энэхүү DAPP-г үргэлжлүүлэн ашиглаж криптовалютын хөрөнгө оруулалт, арилжаа хийвэл энэхүү мэдэгдлийн агуулгыг нарийвчлан уншиж, ойлгосон гэж үзэх бөгөөд үүсч болзошгүй хууль эрх зүйн болон хөрөнгө оруулалтын эрсдлүүдийг хүлээн зөвшөөрсөн гэж үзнэ. Таны хийсэн аливаа үйлдэл нь хамаарах үүрэг хариуцлагыг сайн дураараа хүлээсэн гэж тооцогдох бөгөөд, үүнээс үүдэн гарах үр дагаварт үйл ажиллагааны баг ямар ч хариуцлага хүлээхгүй.</p>
  <br/>
      <h3><strong>4. Үйл ажиллагааны багийн мэдэгдэл:</strong></h3><br/>
      <p>Энэхүү DAPP нь Bull Developer багийн зүгээс боловсруулж, үйл ажиллагаагаа явуулдаг. Бид үргэлж хууль дүрэмд нийцүүлэн ажиллах зарчмыг баримталж, хэрэглэгчдэд өндөр чанартай үйлчилгээ үзүүлэхийг зорьж ирсэн. Гэсэн хэдий ч зарим улс орон, бүс нутгийн онцгой хууль эрх зүйн орчны улмаас бид эдгээр бүс нутагт криптовалюттай холбоотой үйлчилгээг үргэлжлүүлэн үзүүлэх боломжгүй байна. Та бүхэнд энэхүү DAPP-г ашиглахаас өмнө өөрийн оршин суугаа улс орон, бүс нутгийн хууль, дүрэм журмыг ойлгож, дагаж мөрдөхийг зөвлөж байна. Хууль эрх зүйн болон хөрөнгө оруулалтын эрсдлээс зайлсхийх нь чухал юм.</p>
  <br/>
      <p><strong>Дахин талархал илэрхийлье, хэрэв танд асуулт байвал эсвэл нэмэлт тусламж хэрэгтэй бол манай албан ёсны вэбсайтаар дамжуулан бидэнтэй холбоо барина уу.</strong></p>
  <br/>
      <p><strong>Танд хамгийн сайн сайхныг хүсье!</strong></p>
  <br/>
      <p><strong>Bull Developer баг</strong></p>`,
      protocol: `Энэ Түгээмэл Програм (DApp) нь тодорхой бус хөрөнгө оруулалтын эрсдэлтэй. Ашиглахаасаа өмнө эрсдэлтэй байдал болон нууцлалын бодлогыг уншиж, бүрэн ойлгох хэрэгтэй.`,
      more: "Илүү их",
      media: "Холбогдох Социал Медианууд",
      stake:
        "2023 оны 11 сарын 6-нд дэлхий даяар нээлттэй, Telegram болон Twitter дээр биднийг дагаарай!",
      withdraw:
        "2023 оны 11 сарын 6-нд дэлхий даяар нээлттэй, Telegram болон Twitter дээр биднийг дагаарай!",
      Reinvest:
        "2023 оны 11 сарын 6-нд дэлхий даяар нээлттэй, Telegram болон Twitter дээр биднийг дагаарай!",
      Getreward:
        "2023 оны 11 сарын 6-нд дэлхий даяар нээлттэй, Telegram болон Twitter дээр биднийг дагаарай!",
      linkwallet: "Хэтэвчээ холбох",
      notification: "Falcon шатаах хурдатгалын функц удахгүй нээгдэнэ!",
      flashloansOK:'Амжилттай эхэлсэн!',
      flashloansNo:'Эхлэх амжилтгүй болсон!',
      flashloansOnce:'Туршлага олж авах нэг боломжийг авлаа!',
      flashloansError:'Уучлаарай, туршлага олж авах боломж дууссан байна!',
      falocnerror:'Falcon™ V2 туршилтын эрх олгогдоогүй!',
      falocninfo:'Falcon™ V2 тоглогчдод V3 түвшинд батлагдсан! Одоогоор нийтийн туршилтад байна!',
      falconv4:'Falcon™V4 арбитражийн кластер бета туршилтад байна (өдөрт 28000 удаа)!'
    },
      leavel: {
    0:"0",
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
    9: "9",
},
    ido:{
      select: "Олон нийтийг сонгох",
      flashmonkey: "FlashMonkey",
      flashdragon: "FlashDrgon",
      fashFalcon: "Falcon",
      powermonger: "PowerMonger",
      fashfalcon2: "FalconV2",
      other: "Бусад",
      Getmax: "Хамгийн их авах",
      nowbuy: "Одоо захиалах",
      mynumber: "Миний Falcon",
      myrelease: "Гаргасан",
      waitrelease: "Гаргахыг хүлээж байна",
      get: "Татах",
      FalconF: "FlashFalcon(F)",
      FalconA: "FlashFalcon(A)",
      organization: "Байгууллага",
      endTimes:'Эхний үеийн тоолол',
      getmax:'Хамгийн их авах',
      Authorization:"Зөвшөөрөл",
      Subscribe:"Одоо захиалах",
      okSubscribe:"Захиалсан",
      message:"Уламжлал алдаа, Node-ийг өөрчлөнө үү",
      end1:"Эхний үе дууссан",
      needStart:"Эхлэхийн тоолол",
      getscusse:"Амжилттай авсан",
      geterror:"Амжилтгүй болсон"
    },
    top: {
      Account: "Данс",
    },
    stake: {
      Balance: "Таны баланс",
      Total: "Нийт хадгаламж",
      Appoove: "Зөвшөөрөх",
      Stake: "Хадгаламж",
      Appooveing: "Зөвшөөрч байна",
      AuthorSuccessful: "Зөвшөөрөл амжилттай",
      AuthorFailed: "Зөвшөөрөл амжилтгүй болсон",
      Stakeing: "Хадгалж байна",
      StakeSuccessful: "Хадгалалт амжилттай",
      StakeFailed: "Хадгалалт амжилтгүй болсон",
      StakeInfo: "Дараах хэмжээнээс бага байж болохгүй:",
      Activeaccount:"Бүртгэлээ идэвхжүүлэх",
      active:"Бүртгэлээ"

    },
    swap:{
      Swap:"Солилцоо",
      paynameA:"Төлбөр",
      paynameB:"Хүлээн авах",
      Approve:"Зөвшөөрөх",
      launchSwap:"Солилцоог эхлүүлэх",
      recommend:"Зөвлөмж",
      swapError:"Солилцооны алдаа",
      swapNetwork:"Сүлжээний алдаа",
    },
    menu: {
      Home: "Нүүр хуудас",
      Language: "Хэл",
      Document: "Цагаан баримт",
      Contract: "Гэрээ",
      Falcon:"Falcon™",
      Watch: "Ажиглах",
      Coming: "Удахгүй ирнэ",
      EvDao: "Засаглал",
      Flashloan: "Эхлүүлэх",
      Developer:"Хөгжүүлэгчийн бичиг баримт",
      Launch:"Эхлүүлэх",
      xNumber:"Үлдэгдэл",
      xTips:"Автоматаар боловсруулах",
      LogiV2:"Falcon™ V2",
      LogiV3:"Falcon™ V3",
      LogiV4:"Falcon™ V4",
      swap:"Солилцоо",   
      LogiV2pro:"Falcon™ V2+",
      LogiV3tips:"Falcon™ V3 дэлхийн нийтийн туршилт эхэллээ (өдөрт 1000~7000 хүсэлт), харгалзах арбитражийн гэрээнүүдэд зөвшөөрөл шаардлагатай!",
      Appoove2:"Автомат",
      Market:"Зах зээл",
      Ido:"Эрт захиалгын үе 1",
      myIdo:"Миний Falcon",
      interest:"Ашиг",
    },
    Withdraw: {
      Withdraw: "Татах",
      Withdrawable: "Татах боломжтой",
      Earnings: "Орлого",
      Brokerage: "Брокерийн хэмжээ",
      Reinvest: "Дахин хөрөнгө оруулах",
      Getreward: "Шагналаа авах",
      Processing: "Боловсруулж байна",
      unsuccessfule: "Амжилтгүй болсон",
      successful: "Амжилттай!",
      WithdrawInfo1: "Татах хэмжээг оруулна уу",
      WithdrawInfo2: "Хангалтгүй хэмжээ",
      burning:"FalconBurn",
      noBurn: "No Burn",
      addBurn: "Өссөн ашиг",
      addBurnCost: "Өсгөсөн зардал",
      BurnCount: "Шатаах бүртгэл",
      BurnSpeed: "Шатаах хурд",
      needpay: "Төлбөр хийх шаардлагатай",
      startBurn: "Хурдсалтыг эхлүүлэх",
      Burning: "Хурдсалт явж байна"
    },
    Team: {
      innver: "Дотоод",
      Teamstaked: "Багийн хадгаламж",
      leavel: "Хүндэтгэл",
      Team: "Баг",
      Bind: "Холболт",
      info: "Систем түр зуурын холболтыг зогсоосон, нээхийг хүлээнэ үү.",
      Binding: "Холбож байна",
      BindingSuccessful: "Холболт амжилттай",
      BindingFailed: "Холболт амжилтгүй болсон",
      Bindinfo: "Уригчийн хэтэвчийн хаягийг оруулна уу",
    },
    Share: {
      Invitelink: "Урилгын холбоос",
      Intips: `Dapp урилгууд одоогоор боломжгүй байна. 
              Хамт олны хамгийн сүүлийн үеийн шинэчлэлтийг хүлээнэ үү.`,
      copyTitle: "Урилгын холбоос",
      copyText: "Урилгын холбоос амжилттай хуулсан!",
      copyCommnad: "Холбоосыг хуулах",
    },
    Contract:{
    contractaddress: "Гэрээний хаяг",
    Copylink: "Polygonscan",
    CopylinkText: "Polygonscan руу ороорой!",
    },
    Wathch: {
    watchonlywallet: "Зөвхөн ажиглах хэтэвч",
    addwallet: "Хэтэвч нэмэх",
    add: "эмэх",
    Leavel: "Хүндэтгэл",
    share: "Хуваалцах",
    fnOpen:
    "Энэ хэтэвч нь энэ функцэд оролцох урилгыг аваагүй байна!！",
    nullString: "Хэтэвчийн хаягийг оруулна уу!",
    addok:"Амжилттай нэмэгдсэн",
    addError:"Нэмэгдээгүй",
    addisExists:"Энэ хаяг аль хэдийн байна",
    addlist:"Ажиглалтын жагсаалт",
    },
    };
    export default mn;