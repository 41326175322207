<template>
  <div class="progress-bar-container">
    <div class="progress-bar" :style="{ width: animatedWidth }">
      <div class="progress-text-container">
        <span class="progress-text">{{ progress }}%</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    animatedWidth() {
      return `${this.progress}%`;
    },
  },
};
</script>

<style scoped>
.progress-bar-container {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  border: 2px solid #ECA05F;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.progress-bar {
  height: 12px;
  background: url("../assets/img/Gradient2.svg") no-repeat center center/cover;
  border-radius: 10px 0 0 10px;
  position: relative;
  animation: expandWidth 0.6s;
}

@keyframes expandWidth {
  0% {
    width: 0%;
  }

  100% {
    width: (--animatedWidth);
  }
}

.progress-text-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: right;
}

.progress-text {
  color: #fbfbfb;
  font-size: 9px;
  padding-right: 2px;
}
</style>
